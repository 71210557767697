import React, { useEffect, useMemo, useRef, useState } from "react";
import { SecuritiesType } from "../../../reduxtk/types";
import { formatToNaira } from "../../../utils/middleware";

// Define the props for the component
type NewsBannerProps = {
  data: SecuritiesType[];
  onItemClick: (item: SecuritiesType) => void;
};

// Memoize the component to avoid unnecessary rerenders when `data` doesn't change
const NewsBanner: React.FC<NewsBannerProps> = ({ data, onItemClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const tickerRef = useRef<HTMLDivElement>(null);

  // Use effect to handle hover changes and pause/resume animation
  useEffect(() => {
    const tickerElement = tickerRef.current;

    if (tickerElement) {
      // Pause animation when hovered
      if (isHovered) {
        tickerElement.style.animationPlayState = "paused";
      } else {
        tickerElement.style.animationPlayState = "running";
      }
    }
  }, [isHovered]);

  // Memoize the data list to avoid recalculating on each render
  const tickerItems = useMemo(() => {
    // Limit the items to the first 10 to reduce the DOM size
    return [...data, ...data, ...data];
  }, [data]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Memoized TickerItem to avoid unnecessary re-renders
  const TickerItem: React.FC<{
    item: SecuritiesType;
    onItemClick: (item: SecuritiesType) => void;
  }> = React.memo(({ item, onItemClick }) => {
    return (
      <div
        className="flex flex-row items-center justify-center gap-2 item-preview"
        onClick={() => onItemClick(item)}
      >
        <span className="text-xs font-bold whitespace-nowrap">
          {item.symbol}
        </span>
        <span
          className={`ml-2 flex text-xs gap-2 ${
            item.change > 0
              ? "text-green-700"
              : item.change < 0
              ? "text-red-700"
              : "text-yellow-700"
          }`}
        >
          {`(${formatToNaira(item.current_price)})`}
        </span>
        <span
          className={`${
            item.change > 0
              ? "text-green-700"
              : item.change < 0
              ? "text-red-700"
              : "text-yellow-700"
          }`}
        >
          {item?.status === "Up" ? "▲" : item?.status === "Down" ? "▼" : "▲▼"}
        </span>
      </div>
    );
  });

  return (
    <div
      className="relative flex-1 flex h-[20px] overflow-hidden "
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={tickerRef}
        className="flex space-x-8 will-change-transform"
        style={{
          animation: "marquee 120s linear infinite",
        }}
      >
        {tickerItems.map((item, index) => (
          <TickerItem
            key={item?.id + Math.random().toString()}
            item={item}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(NewsBanner);

import { useState } from "react";
import { ImMenu } from "react-icons/im";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Link, Outlet } from "react-router-dom";
import { OnbRouteType } from "../../reduxtk/types";
import OnbFooter from "./OnbFooter";

export const onbRouteObj: OnbRouteType[] = [
  { index: 0, name: "HOME", title: "Home", route: "/" },
  {
    index: 1,
    name: "LEADERBOARD",
    title: "Leaderboard",
    route: "/leaderboard",
  },
  { index: 2, name: "EDUCATION", title: "Education", route: "/education" },
  { index: 3, name: "ABOUT_US", title: "About Us", route: "/aboutus" },
  { index: 4, name: "LOGIN", title: "Login", route: "/login" },
  { index: 5, name: "SIGN_UP", title: "Sign Up", route: "/signup" },
];

const OnbNavigation = () => {
  const [activeNavItem, setActiveNavItem] = useState<OnbRouteType>(
    onbRouteObj[0]
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavItemClick = (route: OnbRouteType) => {
    setActiveNavItem(route);
    setIsMenuOpen(false);
  };

  return (
    <section className="">
      <header className="fixed top-0 left-0 right-0 z-50 flex flex-row items-center justify-between w-full h-24 p-4 bg-cover bg-homepage">
        <section>
          <img
            src={require("../../assets/images/merigame.png")}
            height="35"
            width="111"
            alt="meristem logo"
          />
        </section>

        {/* Navigation Menu for Desktop */}
        <ul className="flex-row justify-between hidden gap-8 lg:flex ">
          {onbRouteObj.slice(0, 4).map((route) => (
            <li
              key={route.index}
              className={`${
                activeNavItem.index === route.index
                  ? "active border-b border-green-400 pt-2"
                  : ""
              }`}
            >
              <Link to={route.route} onClick={() => handleNavItemClick(route)}>
                {route.title}
              </Link>
            </li>
          ))}
        </ul>
        <section>
          <ul className="flex-row items-center justify-between hidden gap-8 lg:flex">
            <Link
              to="login"
              onClick={() => handleNavItemClick(onbRouteObj[4])}
              className={`${
                activeNavItem.index === 4
                  ? "active border-b border-green-400 pt-2 "
                  : ""
              }`}
            >
              Log In
            </Link>
            <Link
              to="signup"
              onClick={() => handleNavItemClick(onbRouteObj[5])}
              className={`${
                activeNavItem.index === 5
                  ? "active border-b border-green-400 pt-2 "
                  : ""
              }`}
            >
              <button className="flex flex-row items-center justify-between gap-4">
                Get Started
                <img
                  src={require("../../assets/images/arrow.png")}
                  alt="get started button"
                  width={28}
                  height={28}
                />
              </button>
            </Link>
          </ul>
        </section>

        {/* Mobile Menu Overlay */}
        <nav className="relative lg:hidden">
          {isMenuOpen ? (
            <IoMdCloseCircleOutline
              size={36}
              color={"red"}
              className=""
              onClick={() => setIsMenuOpen(false)}
            />
          ) : (
            <ImMenu
              size={24}
              color={"white"}
              onClick={() => setIsMenuOpen(true)}
            />
          )}

          {isMenuOpen && (
            <ul className="absolute right-0 z-50 flex flex-col justify-center gap-4 p-2 text-white bg-green-900 rounded-md transition-flex items-left top-12 lg:hidden">
              {onbRouteObj.map((route) => (
                <li
                  key={route.index}
                  className={`${
                    activeNavItem.index === route.index
                      ? "active border-b border-green-400 pt-2"
                      : ""
                  }`}
                >
                  <Link
                    to={route.route}
                    onClick={() => handleNavItemClick(route)}
                  >
                    {route.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </nav>
      </header>
      <section className="hide-scrollbar">
        <Outlet context={{ handleNavItemClick }} />
        <OnbFooter handleNavItemClick={handleNavItemClick} />
      </section>
    </section>
  );
};

export default OnbNavigation;

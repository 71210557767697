import "../../../App.css"; // Import the CSS file for styles

const LoadingSpinner = () => {
  return (
    <div className="items-center flex flex-col justify-center w-full h-[100%]">
      <div className="spinner"></div>
      <p className="text-center loading-text">Loading ...</p>
    </div>
  );
};

export default LoadingSpinner;

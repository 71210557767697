import { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { useAppDispatch, useAppSelector } from "./reduxtk/hooks";
import { updateIsAuthenticated } from "./reduxtk/slices/authSlice";
import { resetAllState } from "./reduxtk/state";
import Toast from "./screens/components/Toast";
import DashboardLayout from "./screens/dashboard/DashboardLayout";
import LoadingSpinner from "./screens/dashboard/components/LoadingSpinner";
import useNetworkStatus from "./screens/hooks/useNetworkStatus";
import OnbNavigation from "./screens/onboarding/OnbNavigation";
import { isUserLoggedIn } from "./utils/Encrypt";
import { showToast } from "./utils/ToastMiddleware";

// components
const ForgotPasswordScreen = lazy(
  () => import("./screens/auth/ForgotPasswordScreen")
);
const LoginScreen = lazy(() => import("./screens/auth/LoginScreen"));
const PasswordScreen = lazy(() => import("./screens/auth/PasswordScreen"));
const ResetPasswordScreen = lazy(
  () => import("./screens/auth/ResetPasswordScreen")
);
const SignupScreen = lazy(() => import("./screens/auth/SignupScreen"));
const VerifyOTPScreen = lazy(() => import("./screens/auth/VerifyOTPScreen"));
const DashboardScreen = lazy(
  () => import("./screens/dashboard/DashboardScreen")
);
const PortfolioHoldingScreen = lazy(
  () => import("./screens/dashboard/PortfolioHoldingScreen")
);
const ReferralsScreen = lazy(
  () => import("./screens/dashboard/ReferralsScreen")
);
const SubmitOrderScreen = lazy(
  () => import("./screens/dashboard/SubmitOrderScreen")
);
const ViewOrderScreen = lazy(
  () => import("./screens/dashboard/ViewOrderScreen")
);
const AboutUsScreen = lazy(() => import("./screens/onboarding/AboutUsScreen"));
const EducationScreen = lazy(
  () => import("./screens/onboarding/EducationScreen")
);
const LeaderboardScreen = lazy(
  () => import("./screens/onboarding/LeaderboardScreen")
);
const OnboardingScreen = lazy(
  () => import("./screens/onboarding/OnboardingScreen")
);

function App() {
  const [theme, setTheme] = useState("theme-1");
  const isOnline = useNetworkStatus();
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.auth.user);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isOnline) {
      showToast("error", "Your Internet is off");
    }
  }, [isOnline]);

  // const toggleTheme = () => {
  //   setTheme((prevTheme) => {
  //     if (prevTheme === "theme-1") {
  //       return "theme-2";
  //     } else if (prevTheme === "theme-2") {
  //       return "theme-3";
  //     } else if (prevTheme === "theme-3") {
  //       return "theme-4";
  //     } else {
  //       return "theme-1";
  //     }
  //   });
  // };

  console.log("USER IN APP : ", user);

  useEffect(() => {
    const updated = isUserLoggedIn();
    resetAllState();
    if (updated) {
      dispatch(updateIsAuthenticated(updated));
    } else {
      dispatch(updateIsAuthenticated(updated));
    }
  }, [isAuthenticated]);

  return (
    <section className={`${theme} w-screen h-screen hide-scrollbar`}>
      <Toast />
      <BrowserRouter>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={<OnbNavigation />}>
              <Route index element={<OnboardingScreen />} />
              <Route path="leaderboard" element={<LeaderboardScreen />} />
              <Route path="education" element={<EducationScreen />} />
              <Route path="aboutus" element={<AboutUsScreen />} />
              <Route path="login" element={<LoginScreen />} />
              <Route path="signup" element={<SignupScreen />} />
              <Route path="forgotpassword" element={<ForgotPasswordScreen />} />

              {user && (
                <Route>
                  <Route path="password" element={<PasswordScreen />} />
                  <Route path="verifyotp" element={<VerifyOTPScreen />} />
                  <Route
                    path="resetpassword"
                    element={<ResetPasswordScreen />}
                  />
                  <Route path="otp" element={<VerifyOTPScreen />} />
                </Route>
              )}
              <Route path="*" element={<LoginScreen />} />
            </Route>

            {/* Dashboard Routes */}
            {isAuthenticated && (
              <Route path="/dashboard" element={<DashboardLayout />}>
                <Route index element={<DashboardScreen />} />
                <Route
                  path="orders/submit_an_order"
                  element={<SubmitOrderScreen />}
                />
                <Route
                  path="orders/view_orders"
                  element={<ViewOrderScreen />}
                />
                <Route path="portfolio" element={<PortfolioHoldingScreen />} />
                {/* <Route
                path="statement/purchase_contract"
                element={<PurchaseScreen />}
              />
              <Route path="statement/sell_contract" element={<SellScreen />} />
              <Route path="statement/cash_contract" element={<CashScreen />} />
              <Route
                path="fund_management/deposit"
                element={<DepositFundsScreen />}
              /> */}
                <Route path="referrals" element={<ReferralsScreen />} />
              </Route>
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </section>
  );
}

export default App;
